.container {
  width: 450px;
  height: 450px;

  @screen lg {
    width: 800px;
    height: 800px;
  }
}

.homeSpecials {
  @apply absolute z-sub right-0 top-0;
  transform: translate(25%, -30%);

  @screen lg {
    @apply top-1/2;
    transform: translate(25%, -50%);
  }
}

.menuDisclaimer {
  @apply absolute z-sub;

  @screen lg {
    @apply top-1/2 left-0;
    transform: translate(-25%, -50%);
  }
}

.menuSection {
  @apply absolute z-sub;

  @screen lg {
    @apply top-0 right-0;
    transform: translate(25%, -25%);
  }
}
